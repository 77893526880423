export const HAS_UNHEALTHY_EVICTABLE_UPGRADE = true;
export const HAS_HPA_ENABLED = false;
export const HAS_WORKLOAD_NETWORK_CHARTS = false;
export const HAS_NEW_WORKLOADS_FILTERS = false;

export const HAS_GPU_SUPPORT = true;
export const HAS_MULTI_PRODUCT_LAYOUT = false;
export const MOCK_HPA_POLICIES_FETCHER = false;
export const HAS_UNEVICTABLE_MENU = true;
export const MOCK_VERSION_WITH_FREE_TRIAL = false;
export const HAS_FREE_TRIAL_NOTIFICATION = true;

export const HAS_MOCKED_SCHEDULE_POLICY_CONFIG = false;
export const HAS_NEW_SCHEDULE_POLICY_LAYOUT = true;

export const HAS_WORKLOAD_HISTORY_EVENTS_CHART = true;
export const MOCKED_HISTORY_EVENTS_CHART_DATA = false;

export const HAS_SCHEDULE_BLOCKERS_GRAPH = true;
export const HAS_NEW_BLOCKED_NODES_TABLE = true;

export const HAS_TROUBLESHOOT_PAGE = true;
export const HAS_NEW_CHARTS_DESIGN = true;

export const FORCE_NO_CLUSTER_MODE = false;
export const HAS_WORKLOAD_OBSERVABILITY = true;

export const HAS_NEW_TROUBLESHOOT_DESIGN = false;
