import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { getDataGridSx, HEADER_HEIGHT } from "../../../../../utils/styleUtils";
import { eBPFTableRow } from "../utils";
import { columns } from "./tableUtils";

interface Props {
  tableData: eBPFTableRow[];
  isLoading: boolean;
}

const WorkloadEbpfTable = ({ tableData, isLoading }: Props) => {
  const [pageSize, setPageSize] = useState<number>(10);
  // genereate unique id for each row
    tableData.forEach((row, index) => {
        row.id = String(index);
    });

  return (
    <DataGrid
      headerHeight={HEADER_HEIGHT}
      rowHeight={60}
      sx={{
        ...getDataGridSx(),
      }}
      columns={columns}
      rows={tableData}
      style={{ height: "100%" }}
      autoHeight={true}
      initialState={{
        sorting: { sortModel: [{ sort: "desc", field: "timestamp" }] },
      }}
      disableSelectionOnClick
      loading={isLoading}
      pageSize={pageSize}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      onPageSizeChange={setPageSize}
      pagination
    />
  );
};

export default WorkloadEbpfTable;
