import {Typography} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {ArrayParam, BooleanParam, ObjectParam, useQueryParam} from "use-query-params";
import { GetObservabilityWorkloadApis, GetObservabilityWorkloadApisResponse , GetNetworkObservabilityEnabled, GetNetworkObservabilityEnabledResponse} from "../../../../api/fetcher";
import { ChartData, SetDate } from "../../../../components/ComposeChart/utils";
import BinocularsIcon from "../../../../Icons/BinocularsIcon";
import SelectViewPeriod from "../SelectViewPeriod";
import { POLICY_TUNING_DATES_URL_PARAM, useViewPeriodQueryParams } from "../utils";
import ErrorChart from "./Charts/ErrorChart";
import LatencyChart from "./Charts/LatencyChart";
import RequestChart from "./Charts/RequestChart";
import { eBPFTableRow } from "./utils";
import WorkloadEbpfTable from "./WorkloadEbpfTable/WorkloadEbpfTable";
import MultiSelectByQueryParams from "../../../../components/MultiSelectByQueryParams";
import FilterChip, {FilterType} from "../../../../components/FilterChip";
import CodeSnippet, {THEME} from "../../../../components/CodeSnippet";
import useGetCustomerToken from "../../../Cost/hooks/useGetCustomerToken";
import useGetVersion from "../../../Cost/hooks/useGetVersion";

const { queryKey, queryFn } = GetObservabilityWorkloadApis();

interface Props {
  kind: string;
  name: string;
  namespace: string;
}

const WorkloadObservabilityContainer = ({ kind, name, namespace }: Props) => {
  kind = kind.toLowerCase();
  console.log(kind, name, namespace);
  const [selectedViewPeriod] = useViewPeriodQueryParams();
  const [urlDates, setPolicyTuningDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);

  const [tableData, setTableData] = useState<eBPFTableRow[]>([]);
  const [requestChartData, setRequestChartData] = useState<ChartData>([]);
  const [errorChartData, setErrorChartData] = useState<ChartData>([]);
  const [latencyChartData, setLatencyChartData] = useState<ChartData>([]);

  const [groupByPathsParams] = useQueryParam("paths",ArrayParam);
  const [isPathsExclude] = useQueryParam("isPathsExclude",BooleanParam);

  const { data: customerTokenData } = useGetCustomerToken();
  const { data: versionData } = useGetVersion();

  const { data: networkObservabilityData } = useQuery<GetNetworkObservabilityEnabledResponse, Error>({
    queryKey: ["networkObservabilityEnabled"],
    queryFn: GetNetworkObservabilityEnabled,
  });

  const { data, isLoading } = useQuery<GetObservabilityWorkloadApisResponse, Error>({
    queryKey: [queryKey, kind, name, namespace, urlDates?.from, urlDates?.to, selectedViewPeriod],
    queryFn: () =>
      queryFn({
        kind,
        name,
        namespace,
        range: selectedViewPeriod + "h",
        // from: dayjs(
        //   urlDates?.from ? Number(urlDates.from) * 1000 : dayjs.utc().subtract(Number(selectedViewPeriod ?? 1), "hour")
        // ).unix(),
        // to: dayjs(urlDates?.to ? Number(urlDates.to) * 1000 : dayjs.utc()).unix(),
      }),
  });

  const setDate: SetDate = ({ from, to }) => {
    setPolicyTuningDates({ from: String(Math.round(Number(from))), to: String(Math.round(Number(to))) });
  };

  // const setDateRange = () => {
  //   if (selectPosition?.from && selectPosition?.to) {
  //     const from = Math.min(selectPosition?.from || 0, selectPosition?.to || firstXPointEpoch || 0) * 1000;
  //     const to = Math.max(selectPosition?.from || 0, selectPosition?.to || lastXPointEpoch || 0) * 1000;
  //     setDates({ from: String(from), to: String(to) });
  //     if (from && to && setSelectedViewPeriod) {
  //       setSelectedViewPeriod(String(Math.round((to - from) / 60 / 60)));
  //     }
  //   }
  //   setSelectPosition(undefined);
  // };

  useEffect(() => {
    /**
     * Set table data
     */
     
    if (data?.httpAPIs) {
      const tableDataToSet: eBPFTableRow[] = data.httpAPIs.map((api) => {
        return {
          id: api.path?.split("/").join("-") ?? "",
          path: api.path ?? "",
          method: api.method ?? "",
          // secure: api.secure ?? false, // TODO is optional
          errorRate: api.dataPoint?.errorRate,
          p50Latency: api.dataPoint?.p50Latency,
          p95Latency: api.dataPoint?.p95Latency,
          // p99Latency: api.dataPoint?.p99Latency,
          requestsPerSecond: api.dataPoint?.requestsPerSecond,
          // timestamp: api.dataPoint?.timestamp,
          // totalRequests: api.dataPoint?.totalRequests,
        };
      });

      setTableData(tableDataToSet);
    }

    /**
     * Set request chart data
     */
     
    if (data?.graphData?.requests) {
      const requestChartDataToSet: ChartData = data.graphData.requests.map((dataPoint) => {
        return {
          timestamp: dayjs(dataPoint.timestamp).toString(),
          values: {
            requests: Number(dataPoint.values?.requests),
            errors: Number(dataPoint.values?.errors),
          },
        }
      });

      setRequestChartData(requestChartDataToSet);
    }

    /**
     * Set error chart data
     */

    if (data?.graphData?.errors?.values) {
      const errorChartDataToSet: ChartData = data.graphData.errors.map((dataPoint) => {
        const values = dataPoint.values
          ? Object.entries(dataPoint.values).reduce((acc, [key, value]) => {
              acc[key] = Number(value);
              return acc;
            }, {} as Record<string, number>)
          : {};
        return {
          timestamp: dayjs(dataPoint.timestamp).toString(),
          values,
        };
      });

      setErrorChartData(errorChartDataToSet);
    }

    /**
     * Set latency chart data
     */

    if (data?.graphData?.latency?.values) {
      const latencyChartDataToSet: ChartData = data.graphData.latency.map((dataPoint) => {
        return {
          timestamp: dayjs(dataPoint.timestamp).toString(),
          values: {
            p50: Number(dataPoint.values?.p50),
            p95: Number(dataPoint.values?.p95),
          },
        };
      });

      setLatencyChartData(latencyChartDataToSet);
    }
  }, [data]);

  return (
      <div className={"flex flex-col gap-5"}>
        <div className="flex border border-border rounded p-4 items-center gap-10">
          <div className="flex gap-10 w-full">
            <BinocularsIcon width={40} height={40}/>
            <Typography variant="body2">
              <b>APIs</b>
              <p>Explore your workload APIs performance</p>
            </Typography>
          </div>
          {networkObservabilityData?.networkObservabilityEnabled && (
              <>
                <div className="h-16 w-[1px] bg-border" />
                <SelectViewPeriod />
              </>
          )}
        </div>

        {!networkObservabilityData?.networkObservabilityEnabled ? (
            <>
              <div className="border border-border rounded p-4">
                <Typography variant="caption">
                  To enable this feature <b>add the following to the installation command</b>:
                </Typography>
              </div>
              <CodeSnippet
                  codeSnippet={`
helm repo add scaleops --username scaleops \\
  --password ${customerTokenData?.token ?? "<SCALEOPS_TOKEN>"} \\
  ${versionData?.helmCommandConfig?.repositoryURL ?? "<SCALEOPS_REPOSITORY>"} \\
  --force-update

helm repo update scaleops
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n ${versionData?.namespace ?? "<SCALEOPS_NAMESPACE>"} -oyaml | \\
helm upgrade scaleops scaleops/scaleops -n ${
                      versionData?.namespace ?? "<SCALEOPS_NAMESPACE>"
                  } --set global.enableNetworkMonitoring=true --set networkMonitor.clusterRole.enabled=true --set global.enableApiObservability=true -f -
`}
                  theme={THEME.light}
                  className="w-full"
              />
              <span className="text-[10px] italic text-text-darkGray">
            Note: This operation will <b>install a DaemonSet</b>.
          </span>
            </>
            ) : (
          <>
            <div className="flex justify-center items-center gap-4">
              <RequestChart graphData={requestChartData} isLoading={isLoading} setDate={setDate}/>
              <ErrorChart graphData={errorChartData} isLoading={isLoading} setDate={setDate}/>
              <LatencyChart graphData={latencyChartData} isLoading={isLoading} setDate={setDate}/>
            </div>

            <div className="w-full flex flex-col gap-2">
            <div className="flex gap-2 items-center">
              <MultiSelectByQueryParams
                options={tableData.map((row) => row.path) ?? []}
                queryKey="paths"
                excludeQueryKey="isPathsExclude"
                hasIsExclude
                hasVirtualizedList
                enableAddCustomValue
              />
            </div>
              <div className="flex gap-2 flex-wrap">
                <FilterChip
                    label="paths"
                    filterType={FilterType.ARRAY}
                    queryParam={"paths"}
                    excludeQueryParam="isPathsExclude"
                />
              </div>
            </div>
            <WorkloadEbpfTable
                tableData={Array.isArray(groupByPathsParams) && groupByPathsParams.length > 0 ? tableData.filter(value => isPathsExclude ? !groupByPathsParams.includes(value.path) : groupByPathsParams.includes(value.path)) : tableData}
                isLoading={isLoading}/>
          </>
        )}
      </div>
        );
        };

export default WorkloadObservabilityContainer;
